<template>
  <div>
    <Main style="margin-top: 2rem">
      <a-row :gutter="25" style="margin-bottom: 1rem">
        <a-col :xxl="{ span: 16, ofset: 4 }" :lg="{ span: 16, offset: 4 }" :xs="{ span: 24 }" style="padding: 2rem">
          <GeneralInformation
            :generalForm="eventForm.generalInfo"
            :eventForm="eventForm"
            :sendForm="submitStatus.generalInfo"
            v-on:getFormValues="checkHandleSubmitForms"
            @getTitle="setTitle"
          />
          <a-row :gutter="25">
            <div class="card w-100">
              <a-row :gutter="30" type="flex" justify="space-between">
                <a-col :lg="9" :xs="24" class="box-event">
                  <p class="title-carousel">Portada del evento</p>
                </a-col>
                <a-col :lg="eventForm.user_image.length ? 24 : 9" :xs="24" style="maxWidth: 100%">
                  <AddImage
                    :error="imageError"
                    :user_image="eventForm.user_image"
                    :sendForm="submitStatus.user_image"
                    :image-selected="eventForm.front_page"
                    :eventForm="eventForm"
                    v-on:getFormValues="checkHandleSubmitForms"
                  />
                </a-col>
              </a-row>
              <Carousel
              v-if="!eventForm.user_image.length"
                :error="imageError"
                :image-selected="eventForm.front_page"
                :user_image="eventForm.user_image"
                @update:imageEvent="eventForm.front_page = $event"
                v-on:getFormValues="checkHandleSubmitForms"
                :sendForm="submitStatus.front_page"
              />
            </div>
          </a-row>
          <ChildEvent
            v-if="eventForm.generalInfo.type == 'normal' && eventForm.generalInfo.grouped_events"
            :subevents="eventForm.subevents"
            :sendFormToParent="submitStatus.subevents"
            :eventForm="eventForm"
            :headquartersFromApi='headquarters'
            v-on:getFormValuesToParent="checkHandleSubmitForms"
          />
          <sede-form
            :sedesForm="eventForm.sedes"
            :sendForm="submitStatus.sedes"
            :eventForm="eventForm"
            :typeEvent="eventForm.generalInfo.type"
            :headquartersFromApi='headquarters'
            v-on:getFormValues="checkHandleSubmitForms"
          />
          <add-files
            :files="eventForm.files"
            :sendForm="submitStatus.files"
            :eventForm="eventForm"
            v-on:getFormValues="checkHandleSubmitForms"
          />
          <event-aimed-at
            v-if=" !eventForm.generalInfo.grouped_events"
            :eventForm="eventForm"
            :sendForm="submitStatus.aimedAt"
            v-on:getFormValues="checkHandleSubmitForms"
          />
          <c-t-registration-view
            v-if="eventForm.generalInfo.type != 'benefits' && eventForm.generalInfo.type != 'other'"
            :registrationForm="eventForm.registration"
            :administrativeAreaIds="eventForm.administrative_area_ids"
            :sendForm="submitStatus.CTRegistration"
            v-on:getFormValues="checkHandleSubmitForms"
            :eventForm="eventForm"
          />
          <a-row :gutter="30" class='events-activities' v-if="eventForm.generalInfo.type != 'benefits' && eventForm.generalInfo.type != 'other'">
            <a-col :md="12" :xs="24">
              <p class="title-normal m-0 mt-20">¿El evento tiene actividades?</p>
              <a-form-item ref="activities" name="activities" class="form-font pt-0 mx-3">
                <a-radio-group v-model:value="eventForm.activities">
                  <a-radio class="form-font" :value="true"> Si </a-radio>
                  <a-radio class="form-font" :value="false"> No </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24" v-if='userRol[0].nombre === "Responsable de evento"'>
              <p class="title-normal m-0 mt-20">Notificaciones</p>
              <a-form-item ref="activities" name="activities" class="form-font pt-0 mx-3">
                <a-checkbox v-model:checked="eventForm.email_notifications">Habilitar notificaciones por correo</a-checkbox>
                <a-checkbox v-model:checked="eventForm.web_notifications">Habilitar notificaciones en sistema</a-checkbox>
              </a-form-item>


            </a-col>
          </a-row>
          <form-maker
            v-if='eventForm.activities'
            :eventForm='eventForm'
            :sendForm="submitStatus.dynamicForm"
            v-on:getFormValues="checkHandleSubmitForms"
          />
          <a-row :gutter="25">
            <a-col :xxl="24" :lg="24" :md="24" :xs="24">
              <a-row :gutter="25" style="text-align: center;">
                <a-col :xxl="11" :lg="11" :md="11" :xs="11" class="mt-4 text-right">
                  <button class="btn-outlined-exit" @click="handleCancelCreateEvent">
                    <span class="title-normal"> Cancelar </span>
                  </button>
                </a-col>
                <a-col :xxl="13" :lg="13" :md="13" :xs="13" class="mt-4 text-left">
                  <a-button :loading="submitStatus.loading" class="btn-fill-add" v-on:click="saveDataNewEvent">
                    <span class="title-normal"> {{ isEdit ? 'Mandar a Validación' : 'Guardar evento' }} </span>
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import { Main } from '@/view/styled.js';
import { defineAsyncComponent, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Notification } from 'ant-design-vue';
import { generateUUID } from '../../utility/utility';
import { getItem } from '@/utility/localStorageControl';
import { createEvent, getHeadquarters } from '@/services/events';
import * as questionTypes from '@/constants/QuestionTypes.js';
import { cctFieldsDic } from '@/constants/CCTFieldsDic.js';

const GeneralInformation = defineAsyncComponent(() => import('./overview/create/GeneralInformation'));
const CTRegistrationView = defineAsyncComponent(() => import('./overview/create/CTRegistrationView'));
const EventAimedAt = defineAsyncComponent(() => import('./overview/create/EventAimedAt'));
const SedeForm = defineAsyncComponent(() => import('./overview/create/SedeForm'));
const AddFiles = defineAsyncComponent(() => import('./overview/create/FileContainer'));
const FormMaker = defineAsyncComponent(() => import('./overview/create/DynamicForm/FormMaker/FormMaker'));
const AddImage = defineAsyncComponent(() => import('./overview/create/UploadImage'));
const Carousel = defineAsyncComponent(() => import('./overview/carousel'));
const ChildEvent = defineAsyncComponent(() => import('./overview/create/ChildEvent.vue'));

export default {
  name: 'CreateView',
  components: {
    Carousel,
    Main,
    GeneralInformation,
    EventAimedAt,
    SedeForm,
    AddFiles,
    AddImage,
    CTRegistrationView,
    FormMaker,
    ChildEvent,
  },

  methods: {
    addCCTQuestion(cct) {
      return {
        id: `Q${generateUUID()}`,
        type: questionTypes.cct_fill,
        title: cctFieldsDic.find(option => option.value === cct)?.label,
        cct_field: cct,
        uuid: '',
        validations: {
          dataType: 'string',
          required: false,
        },
        description: '',
        matrixValidations: {},
        hasDescription: false,
        hasValidations: true,
        isLevel2: false,
        options: [
          { id: `O${generateUUID()}`, content: '', label: 'Opción 1', goto: 'next' },
        ],
        matrixOptions: {
          rows: [{ id: `R${generateUUID()}`, content: '' }],
          cols: [{ id: `C${generateUUID()}`, content: '' }],
        },
        goto: false
      }
    },
    validateCCTQuestion(newForm) {
      const form = JSON.parse(JSON.stringify(newForm));

      form.sections.forEach(section => {
        section.questions.forEach((question, idx) => {
          if (question.type === questionTypes.cct) {
            const previousQuestions = [];
            for (let i = idx + 1; i < section.questions.length; i++) {
              if (section.questions[i]?.type !== questionTypes.cct_fill) break;
              previousQuestions.push(section.questions[i]);
            }
            section.questions.splice(idx + 1, previousQuestions.length); //Delete level 3 questions readOnly
            const newQuestions = question.cct.map(value => {
              //Add previous question if exists, else create it
              return previousQuestions.find(question => question.cct_field === value) || this.addCCTQuestion(value);
            });
            section.questions.splice(idx + 1, 0, ...newQuestions);
          }
        });
      });

      return form;
    },
    checkHandleSubmitForms: function(values) {
      let formStatus = {};
      if (this.eventForm.generalInfo.type == 'other') {
        formStatus = this.formStatusOthers;
      }
      if (this.eventForm.generalInfo.type == 'benefits') {
        formStatus = this.formStatusBenefits;
      }
      if (this.eventForm.generalInfo.type == 'normal' || this.eventForm.generalInfo.type == '') {
        formStatus = this.formStatus;
        if (!this.eventForm.grouped_events) {
          formStatus['subevents'] = true;
        }
        if (this.eventForm.generalInfo.grouped_events) {
          formStatus['aimedAt'] = true;
        }
      }
      if (!values.is_ok && values.typeForm == 'sedes') {
        Notification['error']({
          message: 'Error',
          description: values.messageRequired,
        });
      }
      console.log(formStatus);
      if(formStatus['front_page']){
        formStatus['user_image'] = true;
      }
      if (this.eventForm.user_image.length) {
        formStatus['front_page'] = true;
        this.eventForm.front_page = null
      }
      if (this.eventForm.front_page) {
        formStatus['user_image'] = true;
      }
      formStatus[values.typeForm] = values.is_ok;
      const thereIsError = Object.values(formStatus).includes(false);
      const finishedValidate = !Object.values(formStatus).includes(undefined);
      if (thereIsError && finishedValidate) {
        Notification['error']({
          message: 'Error',
          description: 'Complete el formulario',
        });
        this.submitStatus.loading = false;
      }
      if (!thereIsError && finishedValidate) {
        this.submitStatus.loading = true;
        const data = {
          creator_id: 1,
          admin_can_register: this.eventForm.generalInfo.grouped_events ? 0 : this.eventForm.admin_can_register,
          edu_can_register: this.eventForm.generalInfo.grouped_events ? 0 :this.eventForm.edu_can_register,
          student_can_register: this.eventForm.generalInfo.grouped_events ? 0 :this.eventForm.student_can_register,
          citizen_can_register: this.eventForm.generalInfo.grouped_events ? 1 : this.eventForm.citizen_can_register,
          all_areas: this.eventForm.generalInfo.grouped_events ? [] : this.eventForm.all_areas,
          all_work_areas: this.eventForm.generalInfo.grouped_events ? [] : this.eventForm.all_work_areas,
          administrative_area_ids: this.eventForm.all_work_areas ? [] : this.eventForm.administrative_area_ids,
          work_center_ids: this.eventForm.all_areas ? [] : this.eventForm.work_center_ids,
          subevents: (this.eventForm.subevents.length && this.eventForm.generalInfo.grouped_events) ? this.eventForm.subevents : 'null',
          ...this.eventForm.generalInfo,
          ...this.eventForm.registration,
          amount_participants: parseInt(this.eventForm.generalInfo.amount_participants) || null,
          activities: this.eventForm.activities,
          files: this.eventForm.files,
          headquarters: [],
          questions_json: [],
          front_page: this.eventForm.user_image.length ? null : this.eventForm.front_page,
          user_image: this.eventForm.user_image,
        };
        data.headquarters = this.eventForm.sedes;
        data.questions_json = this.validateCCTQuestion(this.eventForm.dynamicForm);
        data.questions_json.title = data.questions_json.sections[0].title;
        data.questions_json.description = data.questions_json.sections[0].description;
        this.submitStatus.loading = true;
        const form = new FormData();
        this.formatData(form, data);
        //console.log(data);
        createEvent(form)
          .then(response => {
            if (response.success) {
              const description =
                this.userRol[0].nombre === 'Responsable de evento'
                  ? 'Tu evento ha sido enviado al área de validación'
                  : 'Tu evento ha sido guardado exitosamente';
              this.showNotificationAndRedirect('success', 'Éxito', description);
            } else {
              this.showNotificationAndRedirect(
                'error',
                'Error',
                response.message || 'Oops ocurrió un error inesperado',
              );
              this.submitStatus.loading = false;
            }
          })
          .catch(() => {
            //console.log("error", error)
            this.showNotificationAndRedirect('error', 'Error', 'Oops ocurrió un error inesperado');
            this.submitStatus.loading = false;
          });
      }
    },
    setTitle: function(value) {
      this.eventForm.dynamicForm.sections[0].title = value;
    },
    showNotificationAndRedirect: function(type, message, description) {
      Notification[type]({
        message,
        description,
      });
      if (type == 'success') {
        // this.router.push('/');
        // this.submitStatus.loading = false
        setTimeout(() => {
          this.router.push('/');
        }, 5000);
      }
    },
    formatData: function(form, data, prefix = '') {
      for (let key in data) {
        if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
          this.formatData(form, data[key], `${prefix}${key}${prefix ? ']' : ''}[`);
        } else {
          if (typeof data[key] === 'boolean') data[key] = data[key] ? 1 : 0;
          form.append(`${prefix}${key}${prefix ? ']' : ''}`, data[key]);
        }
      }
    },
    handleCancelCreateEvent: function() {
      this.router.push('/');
    },
  },
  async setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const date = new Date();
    const userRol = reactive(getItem('roles'));
    const today =
      date.getDate() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + date.getFullYear();
    const todayFormat = date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + date.getDate();

    dispatch('changeTitleHeader', 'Crear un evento');

    const imageError = ref(false);

    const rowID = `R${generateUUID()}`;
    const colID = `C${generateUUID()}`;

    const eventForm = reactive({
      admin_can_register: false,
      edu_can_register: false,
      student_can_register: false,
      citizen_can_register: false,
      all_areas: false,
      all_work_areas: false,
      administrative_area_ids: [],
      work_center_ids: [],
      activities: false,
      email_notifications: false,
      web_notifications: false,
      subevents: [],
      generalInfo: {
        title: '',
        grouped_events: 0,
        event_date: '',
        event_hour: '',
        end_date: '',
        end_time: '',
        type: 'normal',
        registration_date: today,
        registartion_date_formated: todayFormat,
        start_register_date: '',
        end_register_date: '',
        description: '',
        amount_participants: '',
      },
      sedes: [],
      files: [],
      registration: {
        director_register: 0,
        only_admin_register: 0,
        responsible_id: null,
        info_visible: 1,
        email: '',
        telephone: '',
        assistants: false,
        attendance_record_responsible_id: null,
      },
      dynamicForm: {
        updatedQuestions: [],
        sections: [
          {
            id: `S${generateUUID()}`,
            title: '',
            description: '',
            questions: [
              {
                id: `Q${generateUUID()}`,
                uuid: '',
                validations: {
                  required: false,
                  dataType: 'string',
                  characterType: 'text',
                  validationType: 'limits',
                },
                maxFileSize: 10,
                maxFiles: 1,
                hasDescription: false,
                hasValidations: true,
                title: '',
                description: '',
                type: questionTypes.shortAnswer,
                options: [
                  { id: `O${generateUUID()}`, content: '', label: '', goto: "next", },
                ],
                matrixOptions: {
                  rows: [{ id: rowID, content: '' }],
                  cols: [{ id: colID, content: '' }],
                },
                matrixValidations: {
                  [`T_${colID}_${rowID}`]: {
                    dataType: 'text',
                    validationType: 'limits',
                    min: '',
                    max: '',
                    format: 'email',
                  },
                },
                goto: false,
                layout: 'vertical',
              },
            ],
          },
        ],
        layout: 'vertical',
      },
      front_page: null,
      user_image: [],
    });

    const submitStatus = reactive({
      loading: false,
      aimedAt: false,
      generalInfo: false,
      sedes: false,
      files: false,
      CTRegistration: false,
      dynamicForm: false,
      front_page: false,
      user_image: false,
      subevents: false,
    });

    const formStatus = reactive({
      generalInfo: undefined,
      aimedAt: undefined,
      sedes: undefined,
      files: undefined,
      dynamicForm: undefined,
      CTRegistration: undefined,
      front_page: undefined,
      user_image: undefined,
      subevents: undefined,
    });

    const formStatusOthers = reactive({
      generalInfo: undefined,
      aimedAt: undefined,
      front_page: undefined,
      user_image: undefined,
    });

    const formStatusBenefits = reactive({
      generalInfo: undefined,
      aimedAt: undefined,
      sedes: undefined,
      files: undefined,
      front_page: undefined,
      user_image: undefined,
    });

    const saveDataNewEvent = () => {
      // console.log("si se usa");
      //call emit by prop change on components
      if (eventForm.generalInfo.type == 'benefits') {
        formStatusBenefits.generalInfo = undefined;
        formStatusBenefits.aimedAt = undefined;
        formStatusBenefits.sedes = undefined;
        formStatusBenefits.files = undefined;
        submitStatus.generalInfo = !submitStatus.generalInfo;
        submitStatus.aimedAt = !submitStatus.aimedAt;
        submitStatus.sedes = !submitStatus.sedes;
        submitStatus.files = !submitStatus.files;
        submitStatus.front_page = !submitStatus.front_page;
        submitStatus.user_image = !submitStatus.user_image;
      }
      if (eventForm.generalInfo.type == 'other') {
        formStatusOthers.generalInfo = undefined;
        formStatusOthers.aimedAt = undefined;
        submitStatus.generalInfo = !submitStatus.generalInfo;
        submitStatus.aimedAt = !submitStatus.aimedAt;
        submitStatus.front_page = !submitStatus.front_page;
        submitStatus.user_image = !submitStatus.user_image;
      }
      if (eventForm.generalInfo.type == 'normal' || eventForm.generalInfo.type == '') {
        formStatus.generalInfo = undefined;
        formStatus.aimedAt = undefined;
        formStatus.sedes = undefined;
        formStatus.files = undefined;
        formStatus.CTRegistration = undefined;
        formStatus.subevents = undefined;
        formStatus.dynamicForm = eventForm.activities ? undefined : true;
        submitStatus.generalInfo = !submitStatus.generalInfo;
        submitStatus.aimedAt = !submitStatus.aimedAt;
        submitStatus.sedes = !submitStatus.sedes;
        submitStatus.files = !submitStatus.files;
        submitStatus.dynamicForm = !submitStatus.dynamicForm;
        submitStatus.CTRegistration = !submitStatus.CTRegistration;
        submitStatus.front_page = !submitStatus.front_page;
        submitStatus.user_image = !submitStatus.user_image;
        submitStatus.subevents = !submitStatus.subevents;
      }
    };

    const getHeadquartersFromDB = async()=> {
      let headquarters = []
      const response = await getHeadquarters();
      if (response.success) {
        response.data.forEach(item=> {
          if(!headquarters.find(sede=> item.id==sede.id)){
            item['value'] = item.id;
            item['label'] = item.edificio
            headquarters.push(item)
          }

        })
      }
      return headquarters;
    }
    const headquarters = await getHeadquartersFromDB();
    return {
      router,
      saveDataNewEvent,
      eventForm,
      submitStatus,
      formStatus,
      userRol,
      formStatusOthers,
      formStatusBenefits,
      imageError,
      headquarters
    };
  },

};
</script>

<style scoped lang="sass">
.title-carousel
  font-family: "Avenir Heavy"
.error
  color: red
  font-size: 13px
  font-family: Nutmeg
  display: inline-block
.events-activities
  background-color: #FFFFFF
  border-top: 1px solid #CCCCCC
.btn-fill-add
  border: 1px solid #3FA7E1
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 24px
.btn-disabled:hover
  cursor: not-allowed !important
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin-left: 2rem
</style>
